import { Auth, PhoneAuth, TokenResponse } from '../types';
import { User } from '../AppContext';

import API from '../../utils/API';

export const login = (auth: Auth) => API.post('/api/auth/login', auth);

export const loginWithPhone = (phoneAuth: PhoneAuth): Promise<TokenResponse> =>
  API.post('/api/auth/login/phone', phoneAuth).then((value) => value.data);

export const getUser = (): Promise<User> =>
  API.get('/api/user/details').then((response) => response.data);
