import Cookie from 'universal-cookie';
import { Country, Locale, COOKIE_USER_LOCALE } from './types';
import { getDateYearFromNow } from '../utils/time';

import etMessages from './et.json';
import enMessages from './en.json';
import etLocale from '@fullcalendar/core/locales/et';
import enLocale from '@fullcalendar/core/locales/en-gb';
import { SEGMENT_WRITE_KEY_EE, SEGMENT_WRITE_KEY_KE } from '../config';

export const cookie = new Cookie();
export const availableLocales: {
  [key: string]: Locale;
} = {
  et: {
    key: 'et',
    name: 'Eesti keel',
    messages: etMessages,
    calendarLocale: etLocale,
    pipedrivePlaybookUuid: '08b48dbe-3442-4bd4-87de-6d0a29f5d4cf',
    pipedrivePlaybookTeacherUuid: '64f33c7f-c030-48c6-9a37-15babdd24f56',
  },
  en: {
    key: 'en',
    name: 'English',
    messages: enMessages,
    calendarLocale: enLocale,
    pipedrivePlaybookUuid: 'ca98eb79-dd89-49ac-8753-b4b38bb883dc',
  },
};

export const availableCountries: {
  [key: string]: Country;
} = {
  EE: {
    key: 'EE',
    name: 'Eesti riik',
    tld: 'ee',
    defaultLocale: 'et',
    currencySymbol: '€',
    commissionFeeModifier: 1.25,
    mailSupport: 'info@mentornaut.ee',
    facebookLink: 'https://www.facebook.com/mentornaut',
    instagramLink: 'https://www.instagram.com/mentornaut_eesti/',
    bookingTypeformLink: 'https://ca5p49f8rnn.typeform.com/to/JZYITaW7',
    segmentWriteKey: SEGMENT_WRITE_KEY_EE,
  },
  KE: {
    key: 'KE',
    name: 'Kenya',
    tld: 'ke',
    defaultLocale: 'en',
    currencySymbol: 'KSh',
    commissionFeeModifier: 1.15,
    mailSupport: 'supportkenya@mentornaut.com',
    facebookLink: 'https://www.facebook.com/mentornautkenya',
    instagramLink: 'https://www.instagram.com/mentornaut_kenya/',
    bookingTypeformLink: 'https://ca5p49f8rnn.typeform.com/to/PvUMsnGF',
    segmentWriteKey: SEGMENT_WRITE_KEY_KE,
  },
};

const getUserCountryFromURL = () => {
  const domain = window.location.hostname;

  // this only returns the last segment of the tld, so for example for 'co.uk' only 'uk' is returned
  const tld = domain.split('.').pop();

  if (tld && Object.values(availableCountries).some((value) => value.tld === tld)) {
    const country = Object.values(availableCountries).find((value) => value.tld === tld);
    if (country) return country;
  }

  // default fallback to EE, if domain not recognized
  return availableCountries.EE;
};

export const getUserCountry = () => {
  return getUserCountryFromURL();
};

export const getSelectedLocale = () => {
  // Locale cookie is initially set by backend (user-specific locale)
  const locale: string = cookie.get(COOKIE_USER_LOCALE);

  if (locale && locale in availableLocales) {
    return availableLocales[locale];
  }

  const { defaultLocale } = getUserCountry();

  if (defaultLocale && defaultLocale in availableLocales) {
    return availableLocales[defaultLocale];
  }

  return availableLocales.et;
};

export const setUserLocale = (locale: string) => {
  cookie.set(COOKIE_USER_LOCALE, locale, {
    expires: getDateYearFromNow(),
  });
};
