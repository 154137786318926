import { createTheme } from '@material-ui/core/styles';
// https://material-ui.com/customization/default-theme/

const theme = createTheme({
  typography: {
    fontFamily: 'Nunito, sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: '4rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3.25rem',
    },
    h3: {
      fontWeight: 700,
      fontSize: 24,
    },
    h4: {
      fontWeight: 700,
      fontSize: 20,
    },
    h5: {
      fontWeight: 700,
      fontSize: 14,
    },
    h6: {
      fontSize: 16,
    },
    subtitle1: {
      fontSize: 14,
    },
    subtitle2: {
      fontSize: 12,
    },
    button: {
      textTransform: 'none',
    },
    overline: {
      fontWeight: 700,
      fontSize: '1em',
    },
  },
  palette: {
    common: {
      black: '#142748',
      white: '#FFFFFF',
    },
    primary: {
      main: '#4584EF',
      dark: '#224177',
      light: '#DAE6FC',
      contrastText: '#142748',
    },
    secondary: {
      main: '#34B756',
      light: '#F5FBF6',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#F5F9FE',
      dark: '#4584EF',
    },
    warning: {
      main: '#F8BD0C',
      light: '#FEFBF3',
      dark: '#F1837C',
    },
    error: {
      main: '#E83124',
      dark: '#8F47CD',
      light: '#E9EBFF',
      contrastText: '#f1e0ff',
    },
    success: {
      main: '#D6F1DD',
    },
    grey: {
      100: '#f3f4f6',
      200: '#F4F5F6',
      300: '#D2D6DB',
      400: '#A5AEB7',
      500: '#6b7280',
      600: '#828282',
    },
    text: {
      primary: '#142748',
      secondary: '#1F344B',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  overrides: {
    MuiDrawer: {
      root: {
        // To avoid zIndex type errors cast as any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        zIndex: '1200 !important' as any,
      },
    },
    MuiButton: {
      root: {
        fontSize: '1rem',
        fontWeight: 700,
        boxShadow: 'none !important',
        padding: '5px 20px',
      },
      textSecondary: {
        background: '#F5FBF6',
        '&:hover': {
          backgroundColor: '#D6F0DD',
        },
      },
      containedPrimary: {
        backgroundColor: '#DAE6FC',
        color: '#4584EF',
        '&:hover': {
          color: '#224177',
          backgroundColor: '#DAE6FC',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#34B756',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#34B756',
      },
      current: {
        color: '#34B756',
      },
    },
    MuiFormHelperText: {
      root: {
        color: '#616161',
      },
    },
  },
});
export default theme;
