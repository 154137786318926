import { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import { IntlProvider } from 'react-intl';
import theme from './theme';
import { AppContext, User, UserContextDefaultValue } from './auth/AppContext';
import { getUser } from './auth/services/auth';
import Loader from './components/Loader';
import { cookie, getSelectedLocale, getUserCountry } from './lang/Locale';
import { COOKIE_USER_LOCALE } from './lang/types';
import { CookieChangeOptions } from 'universal-cookie';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/et';
import 'moment/locale/en-gb';
import ScrollToTop from './components/ScrollToTop';

moment.locale(getSelectedLocale().key);

const useStyles = makeStyles(() =>
  createStyles({
    successSnackbar: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    errorSnackbar: {
      backgroundColor: `${theme.palette.error.main} !important`,
    },
  })
);

const App = () => {
  const classes = useStyles();
  const userCountry = getUserCountry().key;
  const [user, setUser] = useState(UserContextDefaultValue.user);
  const [isReady, setIsReady] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState(getSelectedLocale());

  const AppRouter = lazy(() => import('./routes/AppRouter'));
  const AuthWebRouter = lazy(() => import('./routes/AuthWebRouter'));
  const NonAuthWebRouter = lazy(() => import('./routes/NonAuthWebRouter'));

  const getRouter = () => {
    if (!user || !isReady) return <Loader />;

    switch (user.type) {
      case 'TEACHER':
        return <AppRouter />;
      case 'PARENT':
      case 'STUDENT':
        return <AuthWebRouter />;
      default:
        return <NonAuthWebRouter />;
    }
  };

  const handleCookieChange = (event: CookieChangeOptions) => {
    if (event.name === COOKIE_USER_LOCALE) {
      setSelectedLocale(getSelectedLocale());
      moment.locale(getSelectedLocale().key);
    }
  };

  useEffect(() => {
    sessionStorage.removeItem('teacher-search-filters');
    const token = localStorage.getItem('token');
    if (token && !user.type && !user.logOut) {
      getUser().then((response: User) => {
        setUser(response);
        setIsReady(true);
      });
    }
    if (!token) setIsReady(true);
  }, [user]);

  useEffect(() => {
    cookie.addChangeListener(handleCookieChange);
    return () => cookie.removeChangeListener(handleCookieChange);
  }, []);

  const memorizedContextValues = useMemo(() => {
    return {
      userCountry,
      user,
      setUser,
      analytics: undefined, // manually disable all analytics (for now)
    };
  }, [userCountry, user, setUser]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <IntlProvider
        messages={selectedLocale.messages}
        key={`${selectedLocale.key}_${userCountry}`}
        locale={selectedLocale.key}
        defaultLocale="et"
      >
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={1}
            classes={{
              variantSuccess: classes.successSnackbar,
              variantError: classes.errorSnackbar,
            }}
          >
            {/* <AppHelmet user={user} /> */}
            <CssBaseline />
            <Suspense fallback={<Loader />}>
              <AppContext.Provider value={memorizedContextValues}>
                <ScrollToTop />
                {getRouter()}
              </AppContext.Provider>
            </Suspense>
          </SnackbarProvider>
        </ThemeProvider>
      </IntlProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
