import { createContext, Dispatch, SetStateAction } from 'react';
import { Role } from './types';
import { Analytics } from '@segment/analytics-next';

export interface User {
  id?: number;
  firstName?: string;
  lastName?: string;
  type?: Role;
  profileImageUrl?: string;
  logOut?: boolean;
  firstLogin?: boolean;
  hasPaymentMethod?: boolean;
  firstBookingNotFinal?: boolean;
}

type UserContextState = {
  userCountry: string;
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  analytics?: Analytics;
};

export const UserContextDefaultValue: UserContextState = {
  userCountry: '',
  user: {} as User,
  setUser: () => {},
};

export const AppContext = createContext(UserContextDefaultValue);
