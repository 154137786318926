import moment, { Moment } from 'moment';

export const getDateYearFromNow = () => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};

const utcToLocal: (options: {
  utcTime: string;
  incomingFormat: string;
  dateFormat?: string;
  timeFormat?: string;
  date?: string;
}) => string = ({ utcTime, incomingFormat, dateFormat, timeFormat, date }): string => {
  const output = [];
  const localDateTime = moment.utc(utcTime, incomingFormat).local();

  // Return local time based on date
  if (date) {
    const localTime = moment.utc(`${date} ${utcTime}`, 'YYYY-MM-DD HH:mm').local();
    output.push(localTime.format(timeFormat));
    return output.join(' ');
  }

  if (dateFormat) {
    output.push(localDateTime.format(dateFormat));
  }

  if (timeFormat) {
    output.push(localDateTime.format(timeFormat));
  }

  return output.join(' ');
};

export const utcTimeToLocal = (
  utcTime: string,
  date: string,
  incomingFormat = 'HH:mm',
  // Moment LT is not correct with EE locale
  // Temporary fix with HH.mm
  outGoingFormat = 'HH:mm'
): string => {
  return utcToLocal({
    utcTime,
    incomingFormat,
    timeFormat: outGoingFormat,
    date,
  });
};

export const localTimeToUtcTime = (
  localTime: string,
  date: string
  // Moment LT is not correct with EE locale
  // Temporary fix with HH.mm
) => {
  const utcTime = moment(`${date} ${localTime}`, 'YYYY-MM-DD HH:mm:ss').utc().format('HH:mm:ss');

  return utcTime;
};

export const utcDateTimeToLocal: (options: {
  utcTime: string;
  incomingFormat?: string;
  dateFormat?: string;
  timeFormat?: string;
}) => string = ({
  utcTime,
  incomingFormat = 'YYYY-MM-DD HH:mm',
  dateFormat = 'YYYY-MM-DD',
  timeFormat = 'HH:mm',
}): string => {
  return utcToLocal({
    utcTime,
    incomingFormat,
    dateFormat,
    timeFormat,
  });
};

export const roundUpTime = (
  momentObj: Moment,
  roundBy: moment.unitOfTime.DurationConstructor | undefined,
  roundFrom: moment.unitOfTime.StartOf
) => {
  return momentObj.add(1, roundBy).startOf(roundFrom);
};
