import Axios from 'axios';
import { BASE_URL, CORS_ALLOW_CREDENTIALS } from '../../config';
import { RefreshTokenResponse } from './types';
import { addTokenToStorage, clearTokenFromStorage } from './helper';

const axios = Axios.create({
  baseURL: BASE_URL,
});

// Required for local development in order to set cross-domain cookies (i.e refresh_token)
// This should be enabled for the test and dev environment only
if (CORS_ALLOW_CREDENTIALS) {
  axios.defaults.withCredentials = false;
}

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return config;
});

export const refreshToken = (): Promise<boolean> => {
  return new Promise((resolve) =>
    // eslint-disable-next-line no-promise-executor-return
    axios
      .get<RefreshTokenResponse>('/api/auth/refresh')
      .then((response) => {
        addTokenToStorage(response.data.token);
        resolve(true);
      })
      .catch(() => {
        clearTokenFromStorage();
        resolve(false);
      })
  );
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;
    const urlBlackList = ['/api/auth/login', '/api/auth/refresh'];

    if (error.response?.status === 401 && !config.isRetry && !urlBlackList.includes(config.url)) {
      config.isRetry = true;

      const isRefreshed = await refreshToken();

      if (isRefreshed) {
        return axios(config); // Retries the request with a new token
      }
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetcher = (url: string, params?: any) =>
  axios.get(url, params).then((res) => res.data);

export default axios;
