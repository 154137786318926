/* eslint-disable camelcase */
import { LocaleInput } from '@fullcalendar/react';

export const COOKIE_USER_LOCALE = 'user_locale';
export const COOKIE_USER_COUNTRY = 'user_country';
export const COOKIE_USER_COUNTRY_CONFIRMED = 'user_country_confirmed';

export const COUNTRY_NOT_FOUND_IDENTIFIER = 'XX';

export interface GeoIPLookup {
  ip: string | null;
  version: string | null;
  city: string | null;
  region: string | null;
  region_code: string | null;
  country: string | null;
  country_name: string | null;
  country_code: string | null;
  country_code_iso3: string | null;
  country_capital: string | null;
  country_tld: string | null;
  continent_code: string | null;
  in_eu: string | null;
  postal: string | null;
  latitude: string | null;
  longitude: string | null;
  timezone: string | null;
  utc_offset: string | null;
  country_calling_code: string | null;
  currency: string | null;
  currency_name: string | null;
  languages: string | null;
  country_area: string | null;
  country_population: string | null;
  asn: string | null;
  org: string | null;
}

export interface Country {
  key: string;
  name: string;
  tld: string;
  defaultLocale: string;
  currencySymbol: string;
  mailSupport: string;
  facebookLink: string;
  instagramLink: string;
  commissionFeeModifier: number;
  bookingTypeformLink: string;
  segmentWriteKey: string;
}

export interface Locale {
  key: string;
  name: string;
  messages: Record<string, string>;
  calendarLocale: LocaleInput;
  pipedrivePlaybookUuid: string;
  pipedrivePlaybookTeacherUuid?: string;
}
